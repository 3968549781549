import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-http-backend'; // <---- add this
import resources from './locales';

const languages = [
  {
    code: 'en',
    name: 'English (US)',
  },
  {
    code: 'ru',
    name: 'Русский',
  },
  {
    code: 'fr',
    name: 'Français',
  },
  {
    code: 'es',
    name: 'Español',
  },
  {
    code: 'de',
    name: 'Deutsch',
  },
  {
    code: 'it',
    name: 'Italiano',
  },
  {
    code: 'ja',
    name: '日本語',
  },
  {
    code: 'id',
    name: 'Indonesia',
  },
  {
    code: 'zh',
    name: '中文',
  },
  {
    code: 'pt',
    name: 'Português',
  },
  {
    code: 'ar',
    name: 'العربية',
  },
];

const languagedetector = [
  'en',
  'ru',
  'fr',
  'es',
  'de',
  'it',
  'ja',
  'id',
  'zh',
  'pt',
  'ar',
];

const options = {
  order: ['querystring', 'navigator'],
  lookupQuerystring: 'lng',
};

i18n
  .use(XHR)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    detection: options,
    ns: ['translation'],
    defaultNS: 'translation',
    fallbackLng: 'en',
    supportedLngs: languagedetector,
    interpolation: {
      escapeValue: false,
    },
    debug: false,
  });

export { languages, languagedetector };

export default i18n;
