import en from './en.json';
import ru from './ru.json';
import fr from './fr.json';
import es from './es.json';
import de from './de.json';
import it from './it.json';
import ja from './ja.json';
import id from './id.json';
import zh from './zh.json';
import pt from './pt.json';
import ar from './ar.json';

export default {
  ru: { translation: ru },
  en: { translation: en },
  fr: { translation: fr },
  es: { translation: es },
  de: { translation: de },
  it: { translation: it },
  ja: { translation: ja },
  id: { translation: id },
  zh: { translation: zh },
  pt: { translation: pt },
  ar: { translation: ar },
};
